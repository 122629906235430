/*
 * SonarQube
 * Copyright (C) 2009-2022 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.activity-graph-container {
  padding: 10px 0;
}

.activity-graph-tooltip {
  padding: 8px;
}

.activity-graph-tooltip-line {
  height: 20px;
}

.activity-graph-tooltip-line + .activity-graph-tooltip-line {
  padding-top: 4px;
}

.activity-graph-tooltip-issues-line {
  height: 26px;
  padding-bottom: 4px;
}

.activity-graph-tooltip-separator {
  padding-left: 16px;
  padding-right: 16px;
}

.activity-graph-tooltip-separator hr {
  margin-top: 8px;
  margin-bottom: 8px;
}

.activity-graph-tooltip-title,
.activity-graph-tooltip-value {
  font-weight: bold;
}

.activity-graph-legends {
  display: flex;
  justify-content: center;
  padding-bottom: 16px;
}

.activity-graph-legend-actionable {
  display: inline-block;
  padding: 4px 8px 4px
    12px;
  border-width: 1px;
  border-style: solid;
  border-radius: 12px;
}

.activity-graph-new-code-legend {
  margin-right: 10px; /* padding of activity graph */
}

.activity-graph-new-code-legend::after {
  content: '';
  display: inline-block;
  margin-left: 4px;
  width: 8px;
  height: 8px;
  background-color: #fbf3d5;
  border: 2px solid #f1e8cb;
}
